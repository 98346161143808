<i18n src="@/locales/orthodontics.json" />
<i18n src="@/locales/articles-card-content.json" />

<template>
  <div class="orthodontics">
    <div class="header-background">
      <ImanHeader
        :title="$t('h1_title')"
      />
    </div>
    <ImanH2>{{ $t('h2_title_1') }}</ImanH2>
    <ImanArticle>
      <p>
        {{ $t('article_p1') }}
      </p>
    </ImanArticle>
    <section class="section">
      <div class="columns is-centered">
        <div class="column is-half">
          <ImanVideo :video="video" />
        </div>
      </div>
    </section>
    <section class="section">
      <ImanArticle>
        <h3 class="has-text-primary is-size-4">
          {{ $t('h3_title_1') }}
        </h3>
        <p>
          {{ $t('article_p2_1') }}
        </p>
        <div v-html="$t('article_p2_2')" />
        <p>
          {{ $t('article_p2_3') }}
        </p>
      </ImanArticle>
    </section>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[0]">
        <ImanH3>{{ $t('h3_title_2') }}</ImanH3>
        <p>
          {{ $t('article_p3_1') }}
        </p>
        <h4 class="has-text-primary is-size-6">
          {{ $t('h4_title_1') }}
        </h4>
        <p>
          {{ $t('article_p3_2') }}
        </p>
        <h4 class="has-text-primary is-size-6">
          {{ $t('h4_title_2') }}
        </h4>
        <p>
          <span v-html="$t('article_p3_3')" />
        </p>
      </ImanHomeSection>
    </section>
    <section class="section">
      <ImanArticle>
        <h3 class="has-text-primary is-size-4">
          {{ $t('h3_title_3') }}
        </h3>
        <p>
          <span v-html="$t('article_p4_1')" />
        </p>
        <div v-html="$t('article_p4_2')" />
        <h3 class="has-text-primary is-size-4">
          {{ $t('h3_title_4') }}
        </h3>
        <p>
          {{ $t('article_p5') }}
        </p>
      </ImanArticle>
    </section>
    <ImanH2>{{ $t('h2_title_2') }}</ImanH2>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[1]">
        <ImanH3><span v-html="$t('h3_title_5')" /></ImanH3>
        <p>
          {{ $t('article_p6') }}
        </p>
      </ImanHomeSection>
      <ImanHomeSection :img="img[2]">
        <ImanH3><span v-html="$t('h3_title_6')" /></ImanH3>
        <p>
          {{ $t('article_p7_1') }}
        </p>
        <p>
          {{ $t('article_p7_2') }}
        </p>
      </ImanHomeSection>
      <ImanHomeSection :img="img[3]">
        <ImanH3><span v-html="$t('h3_title_7')" /></ImanH3>
        <p>
          {{ $t('article_p8_1') }}
        </p>
      </ImanHomeSection>
    </section>
    <section class="section">
      <ImanArticle>
        <b-message
          type="is-info"
        >
          {{ $t('message_1') }}
        </b-message>
      </ImanArticle>
    </section>
    <ImanH2>{{ $t('h2_title_3') }}</ImanH2>
    <section class="section">
      <ImanArticle>
        <p>
          {{ $t('article_p9') }}
        </p>
      </ImanArticle>
    </section>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[4]">
        <ImanH3>{{ $t('h3_title_8') }}</ImanH3>
        <p>
          {{ $t('article_p10') }}
        </p>
      </ImanHomeSection>
      <ImanHomeSection :img="img[5]">
        <ImanH3>{{ $t('h3_title_9') }}</ImanH3>
        <p>
          <span v-html="$t('article_p11')" />
        </p>
      </ImanHomeSection>
      <ImanHomeSection :img="img[6]">
        <ImanH3><span v-html="$t('h3_title_10')" /></ImanH3>
        <h4 class="has-text-primary is-size-6">
          {{ $t('h4_title_3') }}
        </h4>
        <p>
          {{ $t('article_p12') }}
        </p>
      </ImanHomeSection>
    </section>
    <ImanH2>{{ $t('h2_title_4') }}</ImanH2>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[7]">
        <p>
          <span v-html="$t('article_p13')" />
        </p>
      </ImanHomeSection>
    </section>
    <ImanH2>{{ $t('h2_articles') }}</ImanH2>
    <ImanArticleSection :content-list="filteredArticlesCardContent" />
  </div>
</template>

<script>
  import ImanArticleSection from "@/components/ImanArticleSection";
  import {articlesCardContentFilterMixin} from "@/mixins/articles-card-content-filter-mixin";
  import {metaMixin} from "@/mixins/meta-mixin";

  export default {
    name: 'Orthodontics',
    components: {
      ImanArticleSection
    },
    mixins: [
      articlesCardContentFilterMixin,
      metaMixin
    ],
    data() {
      return {
        articlesCardContentId: [87, 65, 52, 85],
        mainBackgroundClass: 'none-background',
        img: [
          {
            path: 'dental-care/orthodontics/clinique-dentaire-orthodontie.jpg',
            position: 'right'
          },
          {
            path: 'dental-care/orthodontics/consultation-orthodontiste.jpg',
            position: 'right'
          },
          {
            path: 'dental-care/orthodontics/phase-active-orthodontie-redresser-dents.jpg',
            position: 'right'
          },
          {
            path: 'dental-care/orthodontics/barre-de-retention-orthodontie-maintenir-dents.jpg',
            position: 'right'
          },
          {
            path: 'dental-care/orthodontics/bague-orthodontie-invisible.jpg',
            position: 'right'
          },
          {
            path: 'dental-care/orthodontics/brace-linguale-orthodontique.jpg',
            position: 'right'
          },
          {
            path: 'dental-care/orthodontics/gouttiere-orthodontie-invisible.jpg',
            position: 'right'
          },
          {
            path: 'dental-care/orthodontics/resultat-orthodontie-dents-alignees.jpg',
            position: 'right'
          }
        ],
        video: {
          src: 'https://www.youtube.com/embed/JXoXz29TUn4'
        }
      }
    },
    computed: {
      openGraphImgPath () {
        return 'https://anveli.dental' + require('../../../assets/img/thumbnail/MINIATURE_3.6.png')
      }
    },
    created() {
      window.addEventListener('scroll', this.whenScroll)
    },
    destroyed() {
      window.removeEventListener('scroll', this.whenScroll)
    },
    methods: {
      whenScroll() {
        if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
          this.mainBackgroundClass = 'none-background'
        }
        if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
          this.mainBackgroundClass = 'fixed-background'
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.header-background {
    @media only screen and (min-width: 768px) {
      /* tablet et ordinateurs */
      background-image: url("../../../assets/img/dental-care/orthodontics/H1-orthodontie-d.jpeg");
      background-size: cover;
    }
    @media only screen and (max-width: 767px) {
      /* smartphone en mode paysage */
      background-image: url("../../../assets/img/dental-care/orthodontics/H1-orthodontie-t.jpg");
      background-size: cover;
    }
    @media only screen and (max-width: 767px) and (orientation: portrait) {
       /* smartphone en mode portait */
      background-image: url("../../../assets/img/dental-care/orthodontics/H1-orthodontie-m.jpg");
      background-size: cover;
    }
  }
</style>
